/* E X P O R T */

export const canvas = [{
    id: 0,
    name: '',
    canvasBgColor1: '#e7e4e1',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#000000',
    canvasTextColor2: '#ff95b0',
    canvasTextShadow: '',
},{
    id: 1,
    name: '',
    canvasBgColor1: '#b7d33c',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#000000',
    canvasTextColor2: '#ffffff',
    canvasTextShadow: '',
},{
    id: 2,
    name: '',
    canvasBgColor1: '#ff875f',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#000000',
    canvasTextColor2: '#ffffff',
    canvasTextShadow: '',
},{
    id: 3,
    name: '',
    canvasBgColor1: '#ffd64b',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#000000',
    canvasTextColor2: '#ffffff',
    canvasTextShadow: '',
},{
    id: 4,
    name: '',
    canvasBgColor1: '#ff95b0',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#000000',
    canvasTextColor2: '#ffffff',
    canvasTextShadow: '',
},{
    id: 5,
    name: '',
    canvasBgColor1: '#82d8f5',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#000000',
    canvasTextColor2: '#ffffff',
    canvasTextShadow: '',
},{
    id: 6,
    name: '',
    canvasBgColor1: '#0076f5',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'skulls.png',
    canvasTextColor1: '#ffdf07',
    canvasTextColor2: '#ffffff',
    canvasTextShadow:
        `3px 3px rgba(0, 0, 0, 1),
        -1px -1px 5px rgba(0, 0, 0, 0.25)`,
},{
    id: 7,
    name: '',
    canvasBgColor1: 'linear-gradient(135deg, #ffffff 0%, #e9e9e4 100%)',
    canvasBgColor2: '#e7e4e1',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#f3411f',
    canvasTextColor2: '#e7e4e1',
    canvasTextShadow: '',
},{
    id: 8,
    name: '',
    canvasBgColor1: 'linear-gradient(135deg, #aee8fa 0%, #fdfdff 50%, #ffc8dc 100%)',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#622d63',
    canvasTextColor2: '#aee8fa',
    canvasTextShadow: '',
},{
    id: 9,
    name: '',
    canvasBgColor1: 'linear-gradient(135deg, #ff6f8c 0%, #ae21e8 50%, #41d6ff 100%)',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#ffffff',
    canvasTextColor2: '#ff78d9',
    canvasTextShadow: '',
},{
    id: 10,
    name: '',
    canvasBgColor1: 'linear-gradient(135deg, #fff9c5 0%, #feb937 50%, #ffdc45 100%)',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#000000',
    canvasTextColor2: '#ffd64b',
    canvasTextShadow: '',
},{
    id: 11,
    name: '',
    canvasBgColor1: 'linear-gradient(135deg, #f5fbfc 0%, #a1b8d0 50%, #cde9ff 100%)',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#000000',
    canvasTextColor2: '#ff95b0',
    canvasTextShadow: '',
},{
    id: 12,
    name: '',
    canvasBgColor1: 'linear-gradient(135deg, #646266 0%, #333036 100%)',
    canvasBgColor2: '#ffffff',
    canvasBgColor3: '#000000',
    canvasBgImage: 'blanco.png',
    canvasTextColor1: '#ffffff',
    canvasTextColor2: '#82d8f5',
    canvasTextShadow: '',
}]