/* I M P O R T */

/* Module */
import * as THREE from 'three';

/* M A T E R I A L */

const Default = {
    uniforms: {
        uTexture: { type: "t", value: null },
        uTime: { type: "f", value: 0 },
    },
    vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        varying vec2 vUv;
        uniform sampler2D uTexture;
        void main() {
            vec2 uv = vUv;
            vec4 color = texture2D(uTexture, uv);
            gl_FragColor = color;
        }
    `,
}

const Wave = {
    uniforms: {
        uTexture: { type: "t", value: null },
        uTime: { type: "f", value: 0 },
    },
    vertexShader: `
        varying vec2 vUv;
        uniform float uTime;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(
                position.x,
                position.y,
                sin(position.x + position.y + uTime * 1.0),
                1.0
            );
        }
    `,
    fragmentShader: `
        varying vec2 vUv;
        uniform float uTime;
        uniform sampler2D uTexture;
        void main() {
            vec2 uv = vUv;
            vec4 color = texture2D(uTexture, uv);
            gl_FragColor = color;
        }
    `,
}

/* E X P O R T */

export const material = {
    default: () => (
        <shaderMaterial attach="material" args={[Default]} side={THREE.DoubleSide} />
    ),
    wave: () => (
        <shaderMaterial attach="material" args={[Wave]} side={THREE.DoubleSide} />
    ),
}


