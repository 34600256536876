/* E X P O R T */

export const font = [{
    id: 0,
    name: 'arial',
    fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
},{
    id: 1,
    name: 'arial-rounded',
    fontFamily: '"Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
},{
    id: 2,
    name: 'consolas',
    fontFamily: 'Consolas, monaco, monospace',
    fontStyle: 'normal',
    fontWeight: '400',
},{
    id: 3,
    name: 'courier',
    fontFamily: '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
    fontStyle: 'normal',
    fontWeight: '700',
},{
    id: 4,
    name: 'futura',
    fontFamily: 'Futura, "Trebuchet MS", sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
},{
    id: 5,
    name: 'georgia',
    fontFamily: 'Georgia, "Times New Roman", Times, serif',
    fontStyle: 'italic',
    fontWeight: '400',
},{
    id: 6,
    name: 'gillsans',
    fontFamily: '"Gill Sans", "Gill Sans MT", Calibri, sans-serif',
    fontStyle: 'italic',
    fontWeight: '700',
},{
    id: 7,
    name: 'times',
    fontFamily: '"Times New Roman", Times, serif',
    fontStyle: 'normal',
    fontWeight: '400',
},{
    id: 8,
    name: 'trebuchet',
    fontFamily: '"Trebuchet MS", "Lucida Grande", sans-serif',
    fontStyle: 'italic',
    fontWeight: '400',
}]