/* I M P O R T */

/* Module */
import styled from "styled-components";

/* S T Y L E D */

const StyledShare = styled.div(props => {
    const { design } = props;
    return `
        & figure.canvasColor {
            background: ${design.canvasBgColor1};
            background-size: 100%;
        }

        & button {
            color: ${design.canvasTextColor1};
            font-family: ${design.fontFamily};
            font-style: ${design.fontStyle};
            font-weight: ${design.fontWeight};
            text-shadow: ${design.canvasTextShadow};
        }
    `;
});

/* E X P O R T */

export default function EditorListShare(props) {
    /* Variable */
    const { design, render } = props;

    /* ƒ: Share */
    const downloadRender = () => {
        const { print } = render;
        let link = document.createElement('a');
        link.download = 'test.jpg';
        link.href = print;
        link.click();
    };

    /* Render */
    return (
        <StyledShare design={design}>

            <button onClick={() => downloadRender()}>
                <figure className="canvasColor" />
                <figure className="overlay" />
                <span>DOWNLOAD</span>
            </button>

        </StyledShare>
    );
};