/* I M P O R T */

/* Module */
import styled from 'styled-components';

/* Asset */
import placeholder from '../asset/placeholder/texture.png';

/* S T Y L E D */

const StyledPrint = styled.div(props => {
    const { editorMenu, render } = props;
    const { active } = editorMenu;
    return `
        opacity: ${active === 'effect' || active === 'share' ? 1 : 0};

        & .render {
            background: url(${render.model || placeholder});
            background-size: 100%;
        }
    `
});

/* E X P O R T */

export default function EditorFormPrint(props) {
    /* Variable */
    const { design, editorMenu, render } = props;

    /* Render */
    return (
        <StyledPrint design={design} editorMenu={editorMenu} render={render}>

            <figure className="render" />
            
        </StyledPrint>
    );
};