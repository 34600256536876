/* I M P O R T */

/* Module */
import React, { PureComponent, Suspense, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';

/* Asset */
import placeholder from '../asset/placeholder/texture.png';

/* Library */
import { geometry } from '../library/geometry';
import { material } from '../library/material';

/* M E S H */

const Mesh = ({ isCaptured, Geometry, Material, action, render }) => {
    const mesh = useRef(null);
    // Create texture from rendered image
    // If render is not available... Substitute with placeholder
    const image = render.draft || placeholder;
    const texture = useLoader(THREE.TextureLoader, image);
    texture.minFilter = THREE.NearestFilter;
    // Apply texture to mesh
    useEffect(() => {
        mesh.current.material.uniforms.uTexture.value = texture;
    });
    // Apply effects to mesh
    useFrame(({ camera, clock, gl, scene }) => {
        mesh.current.material.uniforms.uTime.value = clock.oldTime * 0.0005;
        // If capture is initiated...
        if (isCaptured === true) {
            action.captureModel(camera, gl, scene);
        }
    });
    // Return
    return (
        <mesh ref={mesh}>
            <Geometry />
            <Material />
        </mesh>
    );
};

/* E X P O R T */

export default class MirrorModel extends PureComponent {
    /* Constructor */
    constructor(props) {
        super(props);
        // ref
        this.mirrorModel = React.createRef();
        // state
        this.state = {
            capture: false,
        };
    };

    /* ƒ: General */
    componentDidUpdate(prevProps, prevState) {
        // If render is requested... Initiate capture
        if (this.props.render !== prevProps.render &&
            (this.props.render.active === 'model' ||
            this.props.render.active === 'print') &&
            this.props.render.draft !== null &&
            this.props.render.model === null) {
            this.setState({ capture: true });
        }
    };
    hasLoaded = () => {
        this.props.action.setAppState({ loader: { mirrorModel: true }});
    };

    /* ƒ: Model */
    captureModel = (camera, gl, scene) => {
        let canvas, imageData, imageType;
        const { action } = this.props;
        gl.render(scene, camera);
        canvas = this.mirrorModel;
        imageType = 'image/png';
        imageData = canvas.toDataURL(imageType);
        action.setAppState({ render: { model: imageData }});
        // Prevent capture from repeating
        this.setState({ capture: false });
    };

    /* Render */
    render() {
        /* Variable */
        const action = { captureModel: this.captureModel }
        const { design, render } = this.props;

        /* Return */
        return (
            <div>

                <Canvas
                    // attribute
                    colorManagement
                    // content
                    ref={node => this.mirrorModel = node}
                    // function
                    onCreated={this.hasLoaded}
                >
                    {/* Light */}
                    <ambientLight />
                    <pointLight position={[10, 10, 10]} />
                    {/* Geometry */}
                    <Suspense fallback={null}>
                        <Mesh
                            isCaptured={this.state.capture}
                            // content
                            Geometry={geometry[design.deformGeometry]}
                            Material={material[design.deformMaterial]}
                            // property
                            action={action}
                            render={render}
                        />
                    </Suspense>
                </Canvas>

            </div>
        );
    };
};