/* I M P O R T */

/* Module */
import styled from 'styled-components';

/* Component */
import EditorFormDraft from './EditorFormDraft';
import EditorFormModel from './EditorFormModel';
import EditorFormPrint from './EditorFormPrint';

/* S T Y L E D */

const StyledForm = styled.div(props => {
    const { design } = props;
    return `
        border-radius: 16px;
        box-shadow: inset 0 0 0 6px ${design.canvasTextColor1};
    `;
});

/* E X P O R T */

export default function EditorForm(props) {
    const { action, design, editorMenu } = props;
    const { setAppState } = action;
    return (
        <StyledForm
            // content
            className="editor-form"
            // function
            onClick={() => setAppState({ editor: { active: 'editorForm' }})}
            // property
            design={design}
        >

            {editorMenu.active === 'canvas' || editorMenu.active === 'font' ?
            <EditorFormDraft {...props} /> : null}
            <EditorFormModel {...props} />
            <EditorFormPrint {...props} />

        </StyledForm>
    ); 
};