/* I M P O R T */

/* Module */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

/* Component */
import MirrorDraft from './MirrorDraft';
import MirrorModel from './MirrorModel';
import MirrorPrint from './MirrorPrint';

/* Style */
import '../style/mirror.css';

/* S T Y L E D */

const StyledMirror = styled.div(props => {
    const { mirror } = props;
    return `
        & > div {
            width: ${mirror.width}px;
            height: ${mirror.height}px;
        }
    `;
});

/* E X P O R T */

export default class Mirror extends PureComponent {
    /* ƒ: General */
    componentDidUpdate(prevProps, prevState) {
        const { action } = this.props;
        const currEditor = this.props.editorMenu.active;
        const prevEditor = prevProps.editorMenu.active;
        // If {editorMenu} is changed...
        // Request relevant render
        // Remove obsolete renders
        if ((currEditor === 'canvas' || currEditor === 'font') &&
            (prevEditor !== 'canvas' && prevEditor !== 'font')) {
            action.setAppState({ render: {
                active: null,
                draft: null,
                model: null,
                print: null,
            }});
        }
        if (currEditor === 'deform' && prevEditor !== 'deform') {
            action.setAppState({ render: {
                active: 'draft',
                model: null,
                print: null,
            }});
        }
        if (currEditor === 'effect' && prevEditor !== 'effect') {
            action.setAppState({ render: {
                active: 'model',
                print: null,
            }});
        }
        if (currEditor === 'share' && prevEditor !== 'share') {
            action.setAppState({ render: {
                active: 'print',
            }});
        }
    }
    
    /* Render */
    render() {
        const { mirror } = this.props;
        return (
            <StyledMirror className="mirror" mirror={mirror}>

                <MirrorDraft {...this.props} />
                <MirrorModel {...this.props} />
                <MirrorPrint {...this.props} />

            </StyledMirror>
        );
    };
};