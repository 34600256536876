/* I M P O R T */

/* Module */
import React, { PureComponent } from "react";
import styled from "styled-components";

/* Library */
import { debounce, hexToRgba } from '../library/function';

/* S T Y L E D */

const StyledDraftInput = styled.textarea(props => {
    const { design } = props;
    return `
        color: ${design.canvasTextColor1};
        font-family: ${design.fontFamily};
        font-style: ${design.fontStyle};
        font-weight: ${design.fontWeight};
        text-shadow: ${design.canvasTextShadow};

        &::placeholder {
            color: ${hexToRgba(design.canvasTextColor1, 0.25)};
        }

        &::selection {
            color: ${hexToRgba(design.canvasTextColor1, 1)};
            background: ${hexToRgba(design.canvasTextColor2, 1)};
        }
    `;
});

/* E X P O R T */

export default class EditorFormDraft extends PureComponent {
    /* Constructor */
    constructor(props) {
        super(props);
        // bind
        this.resizeWindow = this.resizeWindow.bind(this);
        // reference
        this.draft = React.createRef();
        this.draftInput = React.createRef();
        // state
        this.state = {
            fontHeightMax: 0,
            fontHeightMin: 0,
            fontSizeMax: 0,
            width: 0,
        };
    };

    /* ƒ: General */
    componentDidMount() {
        this.scaleDraft();
        this.focusDraftInput();
        this.props.action.setAppState({ renderCategory: { draft: null }});
        // eventListener
        window.addEventListener('resize', this.resizeWindow, false);
    };
    componentWillUnmount() {
        this.props.action.setAppState({ renderCategory: { active: 'draft' }});
        // eventListener
        window.removeEventListener('resize', this.resizeWindow, false);
    };
    componentDidUpdate(prevProps, prevState) {
        // If user changes {design: font}...
        if (this.props.design.fontFamily !== prevProps.design.fontFamily) {
            this.scaleDraftInput();
        }
        // If user changes input...
        if (this.props.editorForm.input !== prevProps.editorForm.input) {
            this.scaleDraftInput();
        }
        // If user highlights form...
        if (this.props.editor.active !== prevProps.editor.active &&
            this.props.editor.active === 'editorForm') {
            this.focusDraftInput();
        }
        // After (scaleDraft)...
        if (this.state.width !== prevState.width) {
            this.scaleDraftInput();
        }
    };

    /* ƒ: Window */
    resizeWindow = debounce(() => {
        this.scaleDraft();
    }, 300);

    /* ƒ: Draft */
    scaleDraft = () => {
        let elem, fontHeightMax, fontHeightMin, fontSizeMax, formScale, width;
        const { action, mirror } = this.props;
        elem = this.draft;
        width = elem.offsetWidth;
        fontSizeMax = Math.round(width * 0.15);
        fontHeightMax = width * 0.8;
        fontHeightMin = width * 0.7;
        formScale = width / mirror.width;
        this.setState({
            fontHeightMax,
            fontHeightMin,
            fontSizeMax,
            width,
        });
        action.setAppState({
            editorForm: { formScale }
        });
    };

    /* ƒ: DraftInput */
    focusDraftInput = () => {
        // Shift focus to last focus point, or end of input
        const elem = this.draftInput;
        if (elem.selectionStart === false || elem.selectionStart === 0) {
            elem.selectionStart = elem.value.length;
            elem.selectionEnd = elem.value.length;
        }
        elem.focus();
    };
    scaleDraftInput = () => {
        let fontScale, fontSize, lineHeight, rows, scrollHeight;
        const { action, editorForm } = this.props;
        const { fontHeightMax, fontHeightMin, fontSizeMax } = this.state;
        const elem = this.draftInput;
        // Scale textarea font attributes
        function scaleInput() {
            lineHeight = Math.round(fontSize * 1.2);
            elem.style.fontSize = fontSize + 'px';
            elem.style.lineHeight = lineHeight + 'px';
            // Scale textarea (scrollHeight) to fit
            elem.style.height = 'auto';
            scrollHeight = elem.scrollHeight;
            elem.style.height = scrollHeight + 'px';
        };
        // Determine whether to scale textarea up or down
        if (editorForm.input === '' || !elem.style.fontSize) {
            fontSize = fontSizeMax;
            scaleInput();
        } else {
            fontSize = elem.style.fontSize.split('px')[0];
            if (fontSize > fontSizeMax) { fontSize = fontSizeMax }
            scaleInput();
        }
        while (scrollHeight < fontHeightMin && fontSize < fontSizeMax) {
            fontSize++;
            scaleInput();
        }
        while (scrollHeight > fontHeightMax && fontSize >= 1) {
            fontSize--;
            scaleInput();
        }
        // Add properties to {editorForm}
        fontScale = fontSize / fontSizeMax;
        rows = Math.round(scrollHeight / lineHeight);
        action.setAppState({
            editorForm: {
                fontScale,
                rows,
            }
        });
    };

    /* Render */
    render() {
        const { action, design, editorForm } = this.props;
        return (
            <form ref={node => this.draft = node}>

                <StyledDraftInput
                    // attribute
                    autoComplete="off"
                    autoCorrect="off"
                    rows="1"
                    spellCheck="false"
                    // content
                    ref={node => this.draftInput = node}
                    value={editorForm.input}
                    placeholder={editorForm.placeholder}
                    // function
                    onChange={event => action.setAppState({
                        editorForm: { input: event.target.value }
                    })}
                    // property
                    design={design}
                />

            </form>
        );
    };
}