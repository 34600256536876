/* E X P O R T */

export const effect = [{
    id: 0,
    name: 'blanco',
    effectImage: 'blanco.png',
    effectOpacity: 0,
},{
    id: 1,
    name: 'checkerboard',
    effectImage: 'checkerboard.png',
    effectOpacity: 0.5,
},{
    id: 2,
    name: 'sand',
    effectImage: 'sand.png',
    effectOpacity: 0.5,
},{
    id: 3,
    name: 'sponge',
    effectImage: 'sponge.png',
    effectOpacity: 0.5,
}]