/* I M P O R T */

/* Module */
import React, { Suspense, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';

/* Asset */
import placeholder from '../asset/placeholder/texture.png';

/* Library */
import { geometry } from '../library/geometry';
import { material } from '../library/material';

/* M E S H */

const Mesh = ({ Geometry, Material, render }) => {
    const mesh = useRef(null);
    // Create texture from rendered image
    // If render is not available... Substitute with placeholder
    const image = render.draft || placeholder;
    const texture = useLoader(THREE.TextureLoader, image);
    texture.minFilter = THREE.NearestFilter;
    // Apply texture to mesh
    useEffect(() => {
        mesh.current.material.uniforms.uTexture.value = texture;
    });
    // Apply effects to mesh
    useFrame(({ clock }) => {
        mesh.current.material.uniforms.uTime.value = clock.oldTime * 0.0005;
    });
    // Return
    return (
        <mesh ref={mesh}>
            <Geometry />
            <Material />
        </mesh>
    );
};

/* S T Y L E D */

const StyledModel = styled.div(props => {
    const { active } = props.editorMenu;
    return `
        opacity: ${active === 'deform' ? 1 : 0};
    `;
});

/* E X P O R T */

export default function EditorFormModel(props) {
    /* Variable */
    const { design, editorMenu, render } = props;

    /* ƒ: General */
    const hasLoaded = () => {
        props.action.setAppState({ loader: { editorFormModel: true }});
    };

    /* Render */
    return (
        <StyledModel editorMenu={editorMenu}>

            <Canvas colorManagement onCreated={hasLoaded}>
                {/* Light */}
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                {/* Geometry */}
                <Suspense fallback={null}>
                    <Mesh
                        // content
                        Geometry={geometry[design.deformGeometry]}
                        Material={material[design.deformMaterial]}
                        // property
                        render={render}
                    />
                </Suspense>
            </Canvas>

        </StyledModel>
    );
};