/* I M P O R T */

/* Module */
import React, { PureComponent } from 'react';
import * as htmlToImage from 'html-to-image';
import styled from 'styled-components';

/* Asset */
import placeholder from '../asset/placeholder/texture.png';
const canvases = require.context('../asset/canvas', false);
const effects = require.context('../asset/effect', false);

/* S T Y L E D */

const StyledPrint = styled.div(props => {
    const { design, render } = props;
    return `
        & .canvasColor {
			background: ${design.canvasBgColor1};
            background-size: 100%;

            z-index: 1;
        }

        & .canvasImage {
			background: url(${canvases('./' + design.canvasBgImage).default});
			background-size: 960px;

            z-index: 2;
        }
        
        & .render {
            background: url(${render.model || placeholder});
            background-size: 100%;

            z-index: 3;
        }
        
        & .effect {
			background: url(${effects('./' + design.effectImage).default});
			background-size: 960px;
			opacity: ${design.effectOpacity};

            z-index: 4;
        }
    `;
});

/* E X P O R T */

export default class MirrorPrint extends PureComponent {
    /* Constructor */
    constructor(props) {
        super(props);
        // ref
        this.print = React.createRef();
        // state
        this.state = {
            capture: false,
        };
    };

    /* ƒ: General */
    componentDidUpdate(prevProps, prevState) {
        // If render is requested... Initiate capture
        if (this.props.render !== prevProps.render &&
            this.props.render.active === 'print' &&
            this.props.render.model !== null &&
            this.props.render.print === null) {
            this.setState({ capture: true });
        }
        // If capture is initiated...
        if (this.state.capture === true) {
            this.capturePrint();
            this.setState({ capture: false });
        }
    };

    /* ƒ: Print */
    capturePrint = () => {
        const { action } = this.props;
        htmlToImage.toJpeg(this.print).then(imageData => {
            action.setAppState({ render: { print: imageData }});
        });
    };

    /* Render */
    render() {
        const { design, render } = this.props;
        return (
            <StyledPrint
                // content
                className="mirror-print"
                ref={node => this.print = node}
                // property
                design={design}
                render={render}
            >

                <figure className="canvasColor" />
                <figure className="canvasImage" />
                <figure className="render" />
                <figure className="effect" />

            </StyledPrint>
        );
    };
};