/* I M P O R T */

/* Module */
import styled from "styled-components";

/* Library */
import { deform } from '../library/deform';

/* S T Y L E D */
const StyledItem = styled.li(props => {
    const { design } = props;
    return `
        color: ${design.canvasTextColor1};
        font-family: ${design.fontFamily};
        font-style: ${design.fontStyle};
        font-weight: ${design.fontWeight};
        text-shadow: ${design.canvasTextShadow};

        > .canvasColor {
            background: ${design.canvasBgColor1};
            background-size: 100%;
            
            z-index: 1;
        }
    `
});

/* E X P O R T */

export default function EditorListCanvas(props) {
    const { action, design, editorList } = props;
    const { deform: id } = editorList;
    return (
        <ul>
            {deform.map(item =>
                <StyledItem
                    // content
                    className={id === item.id ? 'active' : ''}
                    key={item.id}
                    // function
                    onClick={() => action.setAppState({
                        editorList: { deform: item.id }
                    })}
                    // property
                    item={item}
                    design={design}
                >
                    <figure className="canvasColor" />
                    <figure className="overlay" />
                    <span>A</span>
                </StyledItem>
            )}
        </ul>
    );
};