/* I M P O R T */

/* Module */
import styled from 'styled-components';

/* Component */
import EditorForm from './EditorForm';
import EditorList from './EditorList';
import EditorMenu from './EditorMenu';

/* Style */
import '../style/editor.css';

/* S T Y L E D */

const StyledEditor = styled.div(props => {
	const { active } = props.editor;
	return `
		@media (max-width: 540px) {
			& .editor-form {
				height: ${
					active === 'editorForm' ?
					'calc((100vw - 40px) * 0.85)' :
					'calc((100vw - 40px) * 0.33)'
				};
			}
			& .editor-list {
				height: ${
					active === 'editorForm' ?
					'calc((100vw - 40px) * 0.33)' :
					'calc((100vw - 40px) * 0.85)'
				};
			}
		}
	`;
});

/* E X P O R T */

export default function Editor(props) {
	const { editor } = props;
    return (
        <StyledEditor className="editor" editor={editor}>

            <EditorForm {...props} />
            <EditorList {...props} />
			<div className="editor-break" />
            <EditorMenu {...props} />

        </StyledEditor>
    );
};