/* I M P O R T */

/* Module */
import styled from 'styled-components';

/* Component */
import EditorListCanvas from "./EditorListCanvas";
import EditorListDeform from "./EditorListDeform";
import EditorListEffect from "./EditorListEffect";
import EditorListFont from "./EditorListFont";
import EditorListShare from "./EditorListShare";

/* Library */
import { hexToRgba } from '../library/function';

/* S T Y L E D */

const StyledList = styled.div(props => {
    const { design } = props;
    return `
        background: ${design.canvasBgColor2};
        border-radius: 16px;
        box-shadow:
            0 3px 2px ${hexToRgba(design.canvasBgColor3, 0.2)},
            0 0 0 1px ${hexToRgba(design.canvasBgColor3, 0.1)};

        &::-webkit-scrollbar-thumb {
            border-left: 8px solid ${hexToRgba(design.canvasBgColor3, 1)};
        }

        &::-webkit-scrollbar-track {
            border-left: 8px solid ${hexToRgba(design.canvasBgColor3, 0.1)};
        }

        & button figure, ul li figure {
            border-radius: 8px;
        }

        & button figure.overlay, ul li figure.overlay {
            box-shadow:
                inset 0 -8px ${hexToRgba(design.canvasBgColor3, 0.33)},
                inset 0 0 0 1px ${hexToRgba(design.canvasBgColor3, 0.2)};
        }

        & button:hover figure.overlay, ul li:hover figure.overlay {
            box-shadow:
                inset 0 0 ${hexToRgba(design.canvasBgColor3, 0.33)},
                inset 0 0 0 1px ${hexToRgba(design.canvasBgColor3, 0.2)};
        }

        & ul li.active figure.overlay {
            box-shadow:
                inset 0 -8px ${hexToRgba(design.canvasBgColor3, 0.33)},
                inset 0 0 0 4px ${hexToRgba(design.canvasBgColor3, 1)};
        }
        
        & ul li.active:hover figure.overlay {
            box-shadow:
                inset 0 0 ${hexToRgba(design.canvasBgColor3, 0.33)},
                inset 0 0 0 4px ${hexToRgba(design.canvasBgColor3, 1)};
        }
    `;
});

/* E X P O R T */

export default function EditorList(props) {
    const { action, design, editorMenu } = props;
    const { setAppState } = action;
    return (
        <StyledList
            // content
            className="editor-list"
            // function
            onClick={() => setAppState({ editor: { active: 'editorList' }})}
            // property
            design={design}
        >
            
            {(() => {
                switch (editorMenu.active) {
                    case 'canvas': return <EditorListCanvas {...props} />
                    case 'deform': return <EditorListDeform {...props} />
                    case 'effect': return <EditorListEffect {...props} />
                    case 'share': return <EditorListShare {...props} />
                    default: return <EditorListFont {...props} />
                }
            })()}

        </StyledList>
    ); 
};