/* E X P O R T */

export const geometry = {
    box: () => (
        <boxBufferGeometry args={[4.35, 4.35, 4.35]} />
    ),
    plane: () => (
        <planeBufferGeometry args={[7, 7, 32]} />
    ),
    sphere: () => (
        <sphereBufferGeometry args={[2.5, 32, 32]} />
    ),
}