/* I M P O R T */

/* Module */
import styled from "styled-components";

/* Library */
import { effect } from '../library/effect';

/* Asset */
const effects = require.context('../asset/effect', false);

/* S T Y L E D */
const StyledItem = styled.li(props => {
    const { design, item } = props;
    return `
        color: ${design.canvasTextColor1};
        font-family: ${design.fontFamily};
        font-style: ${design.fontStyle};
        font-weight: ${design.fontWeight};
        text-shadow: ${design.canvasTextShadow};

        > .canvasColor {
            background: ${design.canvasBgColor1};
            background-size: 100%;
            
            z-index: 1;
        }

        > .effectImage {
            background: url(${effects('./' + item.effectImage).default});
            background-position: 45% 45%;
            background-size: 150%;
            opacity: ${item.effectOpacity};

            z-index: 2;
        }
    `
});

/* E X P O R T */

export default function EditorListCanvas(props) {
    const { action, design, editorList } = props;
    const { effect: id } = editorList;
    return (
        <ul>
            {effect.map(item =>
                <StyledItem
                    // content
                    className={id === item.id ? 'active' : ''}
                    key={item.id}
                    // function
                    onClick={() => action.setAppState({
                        editorList: { effect: item.id }
                    })}
                    // property
                    item={item}
                    design={design}
                >
                    <figure className="canvasColor" />
                    <figure className="effectImage" />
                    <figure className="overlay" />
                    <span>A</span>
                </StyledItem>
            )}
        </ul>
    );
};