/* I M P O R T */

/* Module */
import styled from 'styled-components';

/* C O M P O N E N T */

const MenuItem = (props) => {
    const { action, editorMenu } = props;
    const { active } = editorMenu;
    return (
        <li
            // content
            className={active === props.type ? 'active' : ''}
            // function
            onClick={() => action.setAppState({
                editorMenu: { active: props.type },
            })}
        >
            <span>{props.type.charAt(0)}</span>
        </li>
    );
}

/* S T Y L E D */

const StyledMenu = styled.div(props => {
    //const { design } = props;
    return `
    `
});

/* E X P O R T */

export default function EditorMenu(props) {
    const { action, design } = props;
    const { setAppState } = action;
    return (
        <StyledMenu
            // content
            className="editor-menu"
            // function
            onClick={() => setAppState({ editor: { active: 'editorMenu' }})}
            // property
            design={design}
        >

            <ul>
                <MenuItem type="canvas" {...props} />
                <MenuItem type="font" {...props} />
                <MenuItem type="deform" {...props} />
                <MenuItem type="effect" {...props} />
                <MenuItem type="share" {...props} />
            </ul>

        </StyledMenu>
    );
};