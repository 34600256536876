/* E X P O R T */

export const deform = [{
    id: 0,
    name: 'tilt',
    deformGeometry: 'box',
    deformMaterial: 'default',
},{
    id: 1,
    name: 'wave',
    deformGeometry: 'plane',
    deformMaterial: 'wave',
},{
    id: 2,
    name: 'bulb',
    deformGeometry: 'sphere',
    deformMaterial: 'default',
}]