/* I M P O R T */

/* Module */
import React, { PureComponent } from "react";
import styled from "styled-components";

/* Component */
import Editor from './Editor';
import Mirror from './Mirror';
import Status from './Status';

/* Library */
import { canvas } from '../library/canvas';
import { deform } from '../library/deform';
import { effect } from '../library/effect';
import { font } from '../library/font';

/* Style */
import '../style/app.css';

/* Asset */
const canvases = require.context('../asset/canvas', false);
const effects = require.context('../asset/effect', false);

/* S T Y L E */

const StyledApp = styled.div(props => {
	const { design, editorForm } = props;
	return `
		> .canvasColor {
			background: ${design.canvasBgColor1};
            background-size: 100%;

			z-index: -3;
		}

		> .canvasImage {
			background: url(${canvases('./' + design.canvasBgImage).default});
			background-size: ${960 * editorForm.formScale}px;

			z-index: -2;
		}

		> .effect {
			background: url(${effects('./' + design.effectImage).default});
			background-size: ${960 * editorForm.formScale}px;
			opacity: ${design.effectOpacity};

			z-index: -1;
		}
	`;
});

/* E X P O R T */

export default class App extends PureComponent {
	/* Constructor */
	constructor(props) {
		super(props);
		// state
		this.state = {
			design: {
				canvasBgColor1: canvas[0].canvasBgColor1,
				canvasBgColor2: canvas[0].canvasBgColor2,
				canvasBgColor3: canvas[0].canvasBgColor3,
				canvasBgImage: canvas[0].canvasBgImage,
				canvasTextColor1: canvas[0].canvasTextColor1,
				canvasTextColor2: canvas[0].canvasTextColor2,
				canvasTextShadow: canvas[0].canvasTexShadow,
				canvasImage: canvas[0].canvasImage,
				deformGeometry: deform[0].deformGeometry,
				deformMaterial: deform[0].deformMaterial,
				effectImage: effect[0].effectImage,
				effectOpacity: effect[0].effectOpacity,
				fontFamily: font[0].fontFamily,
				fontStyle: font[0].fontStyle,
				fontWeight: font[0].fontWeight,
			},
			editor: {
				active: 'editorForm',
			},
			editorForm: {
				input: 'It\'s just a dream',
				placeholder: 'type here',
				fontScale: 1, // font scale relative to form
				formScale: 1, // form scale relative to mirror
				rows: 1,
			},
			editorList: {
				canvas: 0,
				deform: 0,
				effect: 0,
				font: 0,
			},
			editorMenu: {
				active: 'canvas',
			},
			loader: {
				editorFormModel: false, // check if WebGL has loaded
				mirrorModel: false, // check if WebGL has loaded
			},
			mirror: {
				width: 1200,
				height: 1200,
			},
			render: {
				active: null,
				cyclus: 0,
				draft: null,
				model: null,
				print: null,
			},
		};
	};

	/* ƒ: General */
	componentDidMount() {
		// Add {editorList} values to {design}
		const categories = ['canvas', 'deform', 'effect', 'font'];
		categories.map(group => this.setAppStateDesign(group));
	};
	componentDidUpdate(prevProps, prevState) {
		// If any state updates... Add to {render: cyclus} count
		if (this.state.render.cyclus === prevState.render.cyclus) {
			this.setAppState({ render: { cyclus: this.state.render.cyclus + 1 }});
		}
		// If {editorList} updates... Add values to {design}
		if (this.state.editorList !== prevState.editorList) {
			const categories = ['canvas', 'deform', 'effect', 'font'];
			categories.map(group =>
				this.state.editorList[group] !== prevState.editorList[group] ?
				this.setAppStateDesign(group) :
				null
			);
		}
	};

	/* ƒ: App */
	setAppState = (state) => this.setState(prevState => {
		// state ≈ { mirror: { width: 320 }}
    	// Set multiple STATES simultaneously using (setState)
		const nextState = {};
		for (const key in state) {
			nextState[key] = {
				...prevState[key],
				...state[key],
			};
		};
		return nextState;
	});
	setAppStateDesign = (group) => this.setState(prevState => {
		// group ≈ 'canvas'
		// Take (id) from {editorList}
		// Take corresponding (id) from /library/
		// Add values to {design}
		const categories = { canvas, deform, effect, font };
		const id = this.state.editorList[group];
		const { id: x, name: y, ...state} = categories[group][id];
		const nextState = {};
		nextState.design = {
			...prevState.design,
			...state,
		};
		return nextState;
	});

	/* Render */
	render() {
		const { design, editorForm } = this.state;
		const action = { setAppState: this.setAppState };
		return (
			<StyledApp className="app" design={design} editorForm={editorForm}>

				{/* Edit {design} and {editor} */}
				<Editor {...this.state} action={action} />
				{/* Mirror <Editor> and render input */}
				<Mirror {...this.state} action={action} />
				{/* Display {state} */}
				<Status {...this.state} />

				{/* Display {design} */}
				<figure className="canvasColor" />
				<figure className="canvasImage" />
				<figure className="effect" />

			</StyledApp>
		);
	};
};