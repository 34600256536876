/* I M P O R T */

/* Module */
import styled from "styled-components";

/* Library */
import { font } from '../library/font';

/* Asset */
const canvases = require.context('../asset/canvas', false);

/* S T Y L E D */
const StyledItem = styled.li(props => {
    const { design, item } = props;
    return `
        color: ${design.canvasTextColor1};
        font-family: ${item.fontFamily};
        font-style: ${item.fontStyle};
        font-weight: ${item.fontWeight};
        text-shadow: ${design.canvasTextShadow};

        > .canvasColor {
            background: ${design.canvasBgColor1};
            background-size: 100%;

            z-index: 1;
        }

        > .canvasImage {
            background: url(${canvases('./' + design.canvasBgImage).default});
            background-position: 45% 45%;
            background-size: 150%;

            z-index: 2;
        }
    `;
});

/* E X P O R T */

export default function EditorListCanvas(props) {
    const { action, design, editorList } = props;
    const { font: id } = editorList;
    return (
        <ul>
            {font.map(item =>
                <StyledItem
                    // content
                    className={id === item.id ? 'active' : ''}
                    key={item.id}
                    // function
                    onClick={() => action.setAppState({
                        editorList: { font: item.id }
                    })}
                    // property
                    item={item}
                    design={design}
                >
                    <figure className="canvasColor" />
                    <figure className="canvasImage" />
                    <figure className="overlay" />
                    <span>A</span>
                </StyledItem>
            )}
        </ul>
    );
};