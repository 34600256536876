/* I M P O R T */

/* Module */
import React, { PureComponent } from "react";
import * as htmlToImage from 'html-to-image';
import styled from "styled-components";

/* S T Y L E D */

const StyledDraft = styled.div(props => {
    const { design } = props;
    return `
        & p {
            color: ${design.canvasTextColor1};
            font-family: ${design.fontFamily};
            font-style: ${design.fontStyle};
            font-weight: ${design.fontWeight};
        }
    `
});

/* E X P O R T */

export default class MirrorDraft extends PureComponent {
    /* Constructor */
    constructor(props) {
        super(props);
        // ref
        this.draft = React.createRef();
        this.draftInput = React.createRef();
        // state
        this.state = {
            capture: false,
            fontSizeMax: 0,
        }
    };

    /* ƒ: General */
    componentDidMount() {
        this.scaleDraft();
    }
    componentDidUpdate(prevProps, prevState) {
        // If (fontScale) changes...
        if (this.props.editorForm.fontScale !== prevProps.editorForm.fontScale) {
            this.scaleDraftInput();
        }
        // If render is requested... Initiate capture
        if (this.props.render !== prevProps.render &&
            this.props.render.active !== null &&
            this.props.render.draft === null) {
            this.setState({ capture: true });
        }
        // If capture is initiated...
        if (this.state.capture === true) {
            this.captureDraft();
            this.setState({ capture: false });
        }
        // After (scaleDraft)...
        if (this.state !== prevState) {
            this.scaleDraftInput();
        }
    }

    /* ƒ: Draft */
    captureDraft = () => {
        const { action } = this.props;
        htmlToImage.toPng(this.draft).then(imageData => {
            action.setAppState({ render: { draft: imageData }});
        });
    };
    scaleDraft = () => {
        let fontSizeMax;
        const { mirror } = this.props;
        fontSizeMax = Math.round(mirror.width * 0.15);
        this.setState({
            fontSizeMax,
        });
    };

    /* ƒ: DraftInput */
    scaleDraftInput = () => {
        let fontSize, lineHeight;
        const { editorForm } = this.props;
        const { fontSizeMax } = this.state;
        const elem = this.draftInput;
        // Scale paragraph attributes
        fontSize = fontSizeMax * editorForm.fontScale;
        lineHeight = Math.round(fontSize * 1.2);
        elem.style.fontSize = fontSize + 'px';
        elem.style.lineHeight = lineHeight + 'px';
    };

    /* Render */
    render() {
        const { design, editorForm } = this.props;
        return (
            <StyledDraft ref={node => this.draft = node} design={design}>

                <p ref={node => this.draftInput = node}>
                    {editorForm.input}
                </p>

            </StyledDraft>
        );
    };
};