/* I M P O R T */

/* Module */
import styled from 'styled-components';

/* Style */
import '../style/status.css';

/* S T Y L E D */

const StyledStatus = styled.div(props => {
    const { design } = props;
    return `
        color: ${design.canvasTextColor1};

        opacity: 0;

        & ul li figure {
            border: 1px solid ${design.canvasTextColor1};
        }
    `;
});

/* E X P O R T */

export default function Status(props) {
    /* Variable */
    const { design, editor, editorForm, editorList, editorMenu, mirror, loader, render } = props;

    /* Component */
    const Color = props => <figure style={{ background: props.value }} />;

    /* Function */
    const isNull = input => input === null ? 'null' : input;
    const isTrue = input => input ? 'true' : 'false';

    /* Render */
    return (
        <StyledStatus className="status" design={design}>
            <ul aria-label="design">
                <li>canvasBgColor1: <Color value={design.canvasBgColor1} /> </li>
                <li>canvasBgColor2: <Color value={design.canvasBgColor2} /> </li>
                <li>canvasBgColor3: <Color value={design.canvasBgColor3} /> </li>
                <li>canvasBgImage: {design.canvasBgImage}</li>
                <li>canvasTextColor1: <Color value={design.canvasTextColor1} /> </li>
                <li>canvasTextColor2: <Color value={design.canvasTextColor2} /> </li>
                <li>canvasTextShadow: {design.canvasTextShadow}</li>
                <li>deformGeometry: {design.deformGeometry}</li>
                <li>deformMaterial: {design.deformMaterial}</li>
                <li>effectImage: {design.effectImage}</li>
                <li>effectOpacity: {design.effectOpacity}</li>
                <li>fontFamily: {design.fontFamily}</li>
                <li>fontStyle: {design.fontStyle}</li>
                <li>fontWeight: {design.fontWeight}</li>
            </ul>
            <ul aria-label="editor">
                <li>active: {editor.active}</li>
            </ul>
            <ul aria-label="editorForm">
                <li>input: {editorForm.input}</li>
                <li>placeholder: {editorForm.placeholder}</li>
                <li>fontScale: {editorForm.fontScale}</li>
                <li>formScale: {editorForm.formScale}</li>
                <li>rows: {editorForm.rows}</li>
            </ul>
            <ul aria-label="editorList">
                <li>canvas: {editorList.canvas}</li>
                <li>deform: {editorList.deform}</li>
                <li>effect: {editorList.effect}</li>
                <li>font: {editorList.font}</li>
            </ul>
            <ul aria-label="editorMenu">
                <li>active: {editorMenu.active}</li>
            </ul>
            <ul aria-label="loader">
                <li>editorFormModel: {isTrue(loader.editorFormModel)}</li>
                <li>mirrorModel: {isTrue(loader.mirrorModel)}</li>
            </ul>
            <ul aria-label="mirror">
                <li>width: {mirror.width}</li>
                <li>height: {mirror.height}</li>
            </ul>
            <ul aria-label="render">
                <li>active: {isNull(render.active)}</li>
                <li>cyclus: {render.cyclus}</li>
                <li>draft: {isNull(render.draft)}</li>
                <li>model: {isNull(render.model)}</li>
                <li>print: {isNull(render.print)}</li>
            </ul>
        </StyledStatus>
    );
};